body {
    margin: 0;
    font-family: "Nunito", sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6c757d;
    text-align: left;
    background-color: #ffffff; }

a { text-decoration: none !important;}


.leftside-menu {
    flex: 0 0 260px !important;
    width: unset !important;
    position: unset !important;
    display: flex;
    flex-direction: column;
    padding-top: unset !important;
}

#root > .wrapper {
    display: flex !important;
}

.content-page {
    flex: 1 0 400px;
    margin-left: unset !important;
    padding-top: 20px !important;
}

.side-nav {
    flex: 1 0 auto;
}

.logo {
    position: unset !important;
}